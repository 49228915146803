<template>
   <div class="bg-gray-50 hero-bg">
      <div class="relative overflow-hidden pb-28">
         <div class="block absolute inset-y-0 h-full w-full">
            <div class="relative h-full">
               <svg class="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full" width="404" height="784" fill="none" viewBox="0 0 404 784" >
                  <defs>
                     <pattern id="e229dbec-10e9-49ee-8ec3-0286ca089edf" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" >
                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                     </pattern>
                  </defs>
                  <rect width="404" height="784" fill="url(#ad9a0a02-b58e-4a1d-8c36-1b649889af63)" />
               </svg>
               <svg class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4" width="404" height="784" fill="none" viewBox="0 0 404 784" >
                  <defs>
                     <pattern id="d2a68204-c383-44b1-b99f-42ccff4e5365" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" >
                        <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                     </pattern>
                  </defs>
                  <rect width="404" height="784" fill="url(#478e97d6-90df-4a89-8d63-30fdbb3c7e57)" />
               </svg>
            </div>
         </div>
         <div class="relative pt-12 md:pt-2 pb-4 md:pb-12">
            <div class="mx-auto max-w-screen-lg px-4 sm:px-6 md:mt-16 lg:mt-20">
               <div class="text-center">
                  <h2 class="text-3xl md:text-4xl tracking-tight font-extrabold text-white sm:text-5xl sm:leading-none md:text-6xl" > Sell your car to us today! </h2>
                  <span class="text-white text-xl md:text-3xl" >Our system is designed to make the act of buying your vehicle quick, easy and incredibly convenient.</span > 
               </div>
            </div>
         </div>
         <div class="relative">
            <div class="max-w-screen-xl mx-auto px-4 sm:px-6">
               <div class="px-4 sm:px-0">
                  <div class="flex flex-row items-center rounded-lg border-black border-4 mt-3 mb-1 relative shadow mx-auto w-full md:w-1/2" >
                     <div class="flex flex-col items-center"> <span class="flex flex-col bg-blue-800 h-24"> <img class="py-3 px-2" src="../assets/hero/gb.png" alt="" /> </span> </div>
                     <form class="w-full flex flex-row items-center h-24 max-w-2xl bg-yellow-400" action="search" >
                        <label>
                          <input @keyup.enter="navigateToQuote()" v-model="regPlate" type="text" autocomplete="off" class="bg-yellow-400 text-4xl md:text-6xl h-20 uppercase font-bold leading-normal w-full px-3 text-center bg-gray-100 focus:border-gray-100 border-gray-100 relative placeholder-black" placeholder="ENTER REG" value="" name="keyword" />
                          </label> 
                        <button @click="navigateToQuote()" type="submit" class="text-black hover:text-red-800 text-5xl px-3 pt-1" >
                           <svg class="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" >
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" ></path>
                           </svg>
                        </button>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="bg-white">
         <div class="max-w-screen-xl mx-auto pt-12 pb-12 px-4 sm:px-6 md:pb-16 lg:px-8">
            <h3 class="text-center text-black text-sm font-semibold uppercase tracking-wide" > Trusted by over 3,000 customers across the U.K. </h3>
            <div class="mt-8 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
               <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1" > <img class="h-12" src="../assets/hero/brands/audi.svg" alt="Audi" /> </div>
               <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1" > <img class="h-12" src="../assets/hero/brands/bmw.png" alt="BMW" /> </div>
               <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1" > <img class="h-12" src="../assets/hero/brands/mercedes.svg" alt="Mercedes-Benz" /> </div>
               <div class="col-span-1 flex justify-center md:col-span-3 lg:col-span-1" > <img class="h-12" src="../assets/hero/brands/porsche.svg" alt="Porsche" /> </div>
               <div class="col-span-2 flex justify-center md:col-span-3 lg:col-span-1" > <img class="h-12" src="../assets/hero/brands/vw.svg" alt="Volkswagen" /> </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
  name: "Hero",
  data() {
    return {
      regPlate: '',
    }
  },
  methods: {
    navigateToQuote() {
      this.$router.push({ path: 'quote', query: { reg: this.regPlate }})
    }
  }
};
</script> 
<style> .hero-bg { background:linear-gradient(0deg,#2e2954b4, #2e2954b4),url("https://www.buyyourcaronline.co.uk/dist/img/hero/hero-1-1920.jpg"); background-size: cover; } </style>