<template>
   <div class="home">
      <Hero/>
      <div class="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-16 lg:px-8">
         <div class="relative max-w-7xl mx-auto">
            <div class="text-center">
               <img src="https://www.buyyourcaronline.co.uk/dist/img/logo-icon.svg" class="h-28 w-12 mx-auto">
               <h2 class="text-3xl tracking-tight font-extrabold brand-text sm:text-4xl">
                  Buy Your Car Online Group
               </h2>
               <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                  We’re changing the way people buy & sell used cars by providing better selection, value, quality, convenience and peace of mind.
               </p>
            </div>
            <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
               <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div class="flex-shrink-0">
                     <img class="h-48 w-full object-cover" src="https://www.buyyourcaronline.co.uk/dist/img/ql/ql-1.jpg" alt="">
                  </div>
                  <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                     <div class="flex-1">
                        <a href="https://www.buyyourcaronline.co.uk/stock/used-vehicles-in-newcastle-upon-tyne-tyne-and-wear" class="block mt-2">
                           <p class="text-xl font-semibold text-gray-900">
                              Showroom
                           </p>
                           <p class="mt-3 text-base text-gray-500">
                              We’re changing the way people buy used cars by providing better selection, value, quality, convenience and peace of mind.
                           </p>
                        </a>
                     </div>
                     <div class="mt-6 flex items-center">
                        <button class="w-full flex items-center justify-center py-3 px-5 border border-transparent text-base font-medium rounded-md text-white brand-bg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        View our latest cars
                        </button>
                     </div>
                  </div>
               </div>
               <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div class="flex-shrink-0">
                     <img class="h-48 w-full object-cover" src="https://www.buyyourcaronline.co.uk/dist/img/ql/ql-2.jpg" alt="">
                  </div>
                  <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                     <div class="flex-1">
                        <a href="#" class="block mt-2">
                           <p class="text-xl font-semibold text-gray-900">
                              Finance
                           </p>
                           <p class="mt-3 text-base text-gray-500">
                              Need finance for your next car? We can help with that too, offering flexible and affordable rates for all credit types.
                           </p>
                        </a>
                     </div>
                     <div class="mt-6 flex items-center">
                        <button class="w-full flex items-center justify-center py-3 px-5 border border-transparent text-base font-medium rounded-md text-white brand-bg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Get in touch
                        </button>
                     </div>
                  </div>
               </div>
               <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div class="flex-shrink-0">
                     <img class="h-48 w-full object-cover" src="https://www.buyyourcaronline.co.uk/dist/img/ql/ql-1.jpg" alt="">
                  </div>
                  <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                     <div class="flex-1">
                        <a href="#" class="block mt-2">
                           <p class="text-xl font-semibold text-gray-900">
                              Part Exchange
                           </p>
                           <p class="mt-3 text-base text-gray-500">
                              Have an old car to trade in against your new one? Don't fret, we can do that completely online as well.
                           </p>
                        </a>
                     </div>
                     <div class="mt-6 flex items-center">
                        <button class="w-full flex items-center justify-center py-3 px-5 border border-transparent text-base font-medium rounded-md text-white brand-bg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Read more
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
   import Hero from "@/components/Hero";
   
   export default {
     name: "Home",
     components: {
       Hero
     },
   };
</script>
<style>
   .brand-blue-bg {
   background-color: #2e2954;
   }
</style>