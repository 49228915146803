<template>
  <div class="header">
    <div class="max-w-screen-xl mx-auto p-6 sm:p-4 s:py-6">
      <nav
        class="relative flex items-center justify-between sm:h-10 md:justify-center"
      >
        <div
          class="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0"
        >
          <div class="flex items-center justify-between w-full md:w-auto">
            <router-link
              to="/"
              aria-label="Home"
              class="text-white font-bold text-4xl rotate-1"
            >
              <img src="../assets/logo.png" width="320px" />
            </router-link>
            <div class="-mr-2 flex items-center md:hidden">
              <button
                type="button"
                class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                id="main-menu"
                aria-label="Main menu"
                aria-haspopup="true"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="hidden md:flex md:space-x-10 german-flag-border-bottom">
          <router-link
            to="about"
            class="font-medium text-black hover:text-gray-900 transition duration-150 ease-in-out"
            >About Us
          </router-link>
          <a
            href="#"
            class="font-medium text-black hover:text-gray-900 transition duration-150 ease-in-out"
            >Reviews</a
          >
          <a
            href="#"
            class="font-medium text-black hover:text-gray-900 transition duration-150 ease-in-out"
            >Locations</a
          >
          <a
            href="#"
            class="font-medium text-black hover:text-gray-900 transition duration-150 ease-in-out"
            >Get in touch</a
          >
        </div>
        <div
          class="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0"
        >
          <span class="inline-flex rounded-md shadow">
            <a
              href="quote"
              class="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-500 focus:shadow-outline-indigo hover:bg-green-600 transition duration-150 ease-in-out"
            >
              Live Chat
            </a>
          </span>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
};
</script>

<style>
.header {
  @apply border-b-4 bg-white;
}
</style>
